// router.js
import Vue from 'vue'
import VueRouter from 'vue-router'
// 越南namvaytien
// import MyFirst from './namvaytien/MyFirst.vue'
// import MyAbout from './namvaytien/MyAbout.vue'
// import MyProtocol from './namvaytien/MyProtocol.vue'
// import MyQuestion from './namvaytien/MyQuestions.vue'

//泰国chatchaimobile
// import MyFirst from './chatchaimobile/MyFirst.vue'
// import MyProtocol from './chatchaimobile/MyProtocol.vue'


//泰国jaidee
// import MyFirst from './jaidee/MyFirst.vue'
// import MyProtocol from './jaidee/MyProtocol.vue'
// import MyService from './jaidee/MyService.vue'
// import MyQuestion from './jaidee/MyQuestions.vue'
//泰国第二个jaidee
// import MyFirst from './weimingm/MyFirst.vue'
// import MyProtocol from './weimingm/MyProtocol.vue'
// import MyAbout from './weimingm/MyAbout.vue'
// import MyClause from './weimingm/MyClause.vue'

//越南kasikvay
// import MyFirst from './kasikvay/MyFirst.vue'

// import MyAbout from './kasikvay/MyAbout.vue'
// import MyClause from './kasikvay/MyClause.vue'
// import MyQuestions from './kasikvay/MyQuestions.vue'
// import MyProtocol from './kasikvay/MyProtocol.vue'


//越南tamogiefectivo
// import MyFirst from './tamogiefectivo/MyFirst.vue'
// import MyProtocol from './tamogiefectivo/MyProtocol.vue'
// import MyAbout from './tamogiefectivo/MyAbout.vue'
// import MyQuestion from './tamogiefectivo/MyQuestions.vue'
// import MyCenter from './tamogiefectivo/MyCenter.vue'

//墨西哥consedeefectivo
// import MyFirst from './consedeefectivo/MyFirst.vue'
// import MyAbout from './consedeefectivo/MyAbout.vue'
// import MyService from './consedeefectivo/MyService.vue'
// import MyProtocol from './consedeefectivo/MyProtocol.vue'
//墨西哥betacreditdinero
import MyFirst from './betacreditdinero/MyFirst.vue'
import MyAbout from './betacreditdinero/MyAbout.vue'
import MyContact from './betacreditdinero/MyContact.vue'
import MyProtocol from './betacreditdinero/MyProtocol.vue'

//墨西哥cherrypre
// import MyFirst from './cherreypréstamos/MyFirst.vue'
// import MyAbout from './cherreypréstamos/MyAbout.vue'
// import MyService from './cherreypréstamos/MyService.vue'
// import MyQuestions from './cherreypréstamos/MyQuestions.vue'
// import MyProtocol from './cherreypréstamos/MyProtocol.vue'

//越南nathcopay
// import MyFirst from './nathcopay/MyFirst.vue'
// import MyQuestions from './nathcopay/MyQuestions.vue'
// import MyProtocol from './nathcopay/MyProtocol.vue'
Vue.use(VueRouter)
//越南namvaytien
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]

//泰国chatchaimobile
  // const routes = [
  //   {
  //     path: '/',
  //     component: MyFirst, // 默认路由
  //   },
   
  //   {
  //     path: '/protocol',
  //     component: MyProtocol, // protocol 路由
  //   },
  // ]

//泰国jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/service',
//         component: MyService, // /list 路由
//       },
   
//   ]
//泰国第二个jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]
//未命名
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/clause',
//     component: MyClause, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
 
// ]

//越南kasikvay
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]

//越南tamogiefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestion, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/center',
//       component: MyCenter, // /list 路由
//     },
 
 
// ]

//墨西哥consedeefectivo
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
 
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]


// 越南betacreditdinero
const routes = [
    {
      path: '/',
      component: MyFirst, // 默认路由
    },
    {
      path: '/about',
      component: MyAbout, // /list 路由
    },
    
  
    {
      path: '/contant',
      component: MyContact, // /list 路由
    },
    {
      path: '/protocol',
      component: MyProtocol, // product 路由
    },
  
   
  ]
// 越南nathcopay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // /list 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

 
// ]
const router = new VueRouter({
  mode: 'hash', // 设置路由模式为 hash
  routes
})

export default router
